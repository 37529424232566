import React, { useState } from 'react';
import { ref, set, get, update } from 'firebase/database';
import { database } from './Firebase';

const ShareLocation = () => {
  const [isSharing, setIsSharing] = useState(false);
  const [watchId, setWatchId] = useState(null);

  const handleLocationToggle = async () => {
    const activeStreamRef = ref(database, 'active_stream');
    const snapshot = await get(activeStreamRef);
    const streamId = snapshot.val();

    if (!streamId) {
      console.error("No active stream found.");
      return;
    }

    if (isSharing) {
      navigator.geolocation.clearWatch(watchId);
      setWatchId(null);
      setIsSharing(false);

      const locationRef = ref(database, `/live_streams/${streamId}/location`);
      await set(locationRef, null);

      const isSharingRef = ref(database, `/live_streams/${streamId}/isSharing`);
      await update(isSharingRef, { isSharing: false });

    } else {
      const id = navigator.geolocation.watchPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          const locationRef = ref(database, `/live_streams/${streamId}/location`);
          await set(locationRef, { latitude, longitude });
        },
        (error) => console.error("Error sharing location:", error),
        { enableHighAccuracy: true }
      );

      setWatchId(id);
      setIsSharing(true);

      const locationRef = ref(database, `/live_streams/${streamId}/isSharing`);
      await update(locationRef, { isSharing: true });
    }
  };

  return (
    <div>
      <h1>{isSharing ? "Helymegosztás engedélyezve" : "Helymegosztás letiltva"}</h1>
      <button onClick={handleLocationToggle}>
        {isSharing ? "Megosztás leállítása" : "Megosztás indítása"}
      </button>
      <p>{isSharing ? "A tartózkodási helyed valós időben osztjuk meg." : "A tartózkodási helyed nem osztjuk meg."}</p>
    </div>
  );
};

export default ShareLocation;
