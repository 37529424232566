import React from 'react'

import PropTypes from 'prop-types'

import './csapat.css'

const Csapat = (props) => {
  return (
    <div className="csapat-team8 thq-section-padding">
      <animate-on-reveal
        animation="fadeIn"
        duration="500ms"
        delay="0s"
        direction="normal"
        easing="ease"
        iteration="1"
      >
        <div
          data-thq-animate-on-reveal="true"
          className="csapat-max-width thq-section-max-width"
        >
          <div className="csapat-section-title">
            <div className="csapat-content">
              <h2 className="thq-heading-2">{props.heading1}</h2>
            </div>
          </div>
          <div
            data-thq="slider"
            data-loop="true"
            data-autoplay="true"
            data-navigation="true"
            data-pagination="true"
            data-disable-autoplay-on-interaction="true"
            className="csapat-slider swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="csapat-slider-slide swiper-slide"
              >
                <div className="csapat-card">
                  <img
                    alt={props.member1Alt}
                    src={props.member1Src}
                    className="csapat-placeholder-image thq-img-ratio-1-1"
                  />
                  <div className="csapat-content01">
                    <div className="csapat-title">
                      <span className="csapat-text01 thq-body-small">
                        {props.member1}
                      </span>
                    </div>
                    <span className="csapat-text02 thq-body-small">
                      {props.member1Content}
                    </span>
                  </div>
                </div>
                <div className="csapat-card01">
                  <img
                    alt={props.member2Alt}
                    src={props.member2Src}
                    className="csapat-placeholder-image01 thq-img-ratio-1-1"
                  />
                  <div className="csapat-content02">
                    <div className="csapat-title01">
                      <span className="csapat-text03 thq-body-small">
                        {props.member2}
                      </span>
                    </div>
                    <span className="csapat-text04 thq-body-small">
                      {props.member2Content}
                    </span>
                  </div>
                </div>
              </div>
              <div
                data-thq="slider-slide"
                className="csapat-slider-slide1 swiper-slide"
              >
                <div className="csapat-card02">
                  <img
                    alt={props.member3Alt}
                    src={props.member3Src}
                    className="csapat-placeholder-image02 thq-img-ratio-1-1"
                  />
                  <div className="csapat-content03">
                    <div className="csapat-title02">
                      <span className="csapat-text05 thq-body-small">
                        {props.member3}
                      </span>
                    </div>
                    <span className="csapat-text06 thq-body-small">
                      {props.member3Content}
                    </span>
                  </div>
                </div>
                <div className="csapat-card03">
                  <img
                    alt={props.member4Alt}
                    src={props.member4Src}
                    className="csapat-placeholder-image03 thq-img-ratio-1-1"
                  />
                  <div className="csapat-content04">
                    <div className="csapat-title03">
                      <span className="csapat-text07 thq-body-small">
                        {props.member4}
                      </span>
                    </div>
                    <span className="csapat-text08 thq-body-small">
                      {props.member4Content}
                    </span>
                  </div>
                </div>
              </div>
              <div
                data-thq="slider-slide"
                className="csapat-slider-slide2 swiper-slide"
              >
                <div className="csapat-card04">
                  <img
                    alt={props.member5Alt}
                    src={props.member5Src}
                    className="csapat-placeholder-image04 thq-img-ratio-1-1"
                  />
                  <div className="csapat-content05">
                    <div className="csapat-title04">
                      <span className="csapat-text09 thq-body-small">
                        {props.member5}
                      </span>
                    </div>
                    <span className="csapat-text10 thq-body-small">
                      {props.member5Content}
                    </span>
                  </div>
                </div>
                <div className="csapat-card05">
                  <img
                    alt={props.member6Alt}
                    src={props.member6Src}
                    className="csapat-placeholder-image05 thq-img-ratio-1-1"
                  />
                  <div className="csapat-content06">
                    <div className="csapat-title05">
                      <span className="csapat-text11 thq-body-small">
                        {props.member6}
                      </span>
                    </div>
                    <span className="csapat-text12 thq-body-small">
                      {props.member6Content}
                    </span>
                  </div>
                </div>
                <div className="csapat-card06">
                  <img
                    alt={props.member7Alt}
                    src={props.member7Src}
                    className="csapat-placeholder-image05 thq-img-ratio-1-1"
                  />
                  <div className="csapat-content06">
                    <div className="csapat-title05">
                      <span className="csapat-text11 thq-body-small">
                        {props.member7}
                      </span>
                    </div>
                    <span className="csapat-text12 thq-body-small">
                      {props.member7Content}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="csapat-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="swiper-button-next"
            ></div>
          </div>
          <div
            data-thq="slider"
            data-navigation="true"
            data-pagination="true"
            className="csapat-slider1 swiper"
          >
            <div data-thq="slider-wrapper" className="swiper-wrapper">
              <div
                data-thq="slider-slide"
                className="csapat-slider-slide3 swiper-slide"
              >
                <div className="csapat-card06">
                  <img
                    alt={props.member1Alt}
                    src={props.member1Src}
                    className="thq-img-ratio-1-1 csapat-placeholder-image06"
                  />
                  <div className="csapat-content07">
                    <div className="csapat-title06">
                      <span className="csapat-text13 thq-body-small">
                        {props.member1}
                      </span>
                    </div>
                    <span className="csapat-text21 thq-body-small">
                      {props.member1Content}
                    </span>
                  </div>
                </div>
              </div>
              <div
                data-thq="slider-slide"
                className="csapat-slider-slide4 swiper-slide"
              >
                <div className="csapat-card07">
                  <img
                    alt={props.member2Alt}
                    src={props.member2Src}
                    className="thq-img-ratio-1-1 csapat-placeholder-image07"
                  />
                  <div className="csapat-content08">
                    <div className="csapat-title07">
                      <span className="csapat-text16 thq-body-small">
                        {props.member2}
                      </span>
                    </div>
                    <span className="csapat-text21 thq-body-small">
                      {props.member2Content}
                    </span>
                  </div>
                </div>
              </div>
              <div
                data-thq="slider-slide"
                className="csapat-slider-slide5 swiper-slide"
              >
                <div className="csapat-card08">
                  <img
                    alt={props.member3Alt}
                    src={props.member3Src}
                    className="thq-img-ratio-1-1 csapat-placeholder-image08"
                  />
                  <div className="csapat-content09">
                    <div className="csapat-title08">
                      <span className="csapat-text19 thq-body-small">
                        {props.member3}
                      </span>
                    </div>
                    <span className="csapat-text21 thq-body-small">
                      {props.member3Content}
                    </span>
                  </div>
                </div>
              </div>
              <div
                data-thq="slider-slide"
                className="csapat-slider-slide6 swiper-slide"
              >
                <div className="csapat-card09">
                  <img
                    alt={props.member4Alt}
                    src={props.member4Src}
                    className="thq-img-ratio-1-1 csapat-placeholder-image09"
                  />
                  <div className="csapat-content10">
                    <div className="csapat-title09">
                      <span className="csapat-text25 thq-body-small">
                        {props.member4}
                      </span>
                    </div>
                    <span className="csapat-text27 thq-body-small">
                      {props.member4Content}
                    </span>
                  </div>
                </div>
              </div>
              <div
                data-thq="slider-slide"
                className="csapat-slider-slide7 swiper-slide"
              >
                <div className="csapat-card10">
                  <img
                    alt={props.member5Alt}
                    src={props.member5Src}
                    className="thq-img-ratio-1-1 csapat-placeholder-image10"
                  />
                  <div className="csapat-content11">
                    <div className="csapat-title10">
                      <span className="csapat-text25 thq-body-small">
                        {props.member5}
                      </span>
                    </div>
                    <span className="csapat-text27 thq-body-small">
                      {props.member5Content}
                    </span>
                  </div>
                </div>
              </div>
              <div
                data-thq="slider-slide"
                className="csapat-slider-slide8 swiper-slide"
              >
                <div className="csapat-card11">
                  <img
                    alt={props.member6Alt}
                    src={props.member6Src}
                    className="thq-img-ratio-1-1 csapat-placeholder-image11"
                  />
                  <div className="csapat-content12">
                    <div className="csapat-title11">
                      <span className="csapat-text29 thq-body-small">
                        {props.member6}
                      </span>
                    </div>
                    <span className="csapat-text31 thq-body-small">
                      {props.member6Content}
                    </span>
                  </div>
                </div>
                </div>
              <div
                data-thq="slider-slide"
                className="csapat-slider-slide9 swiper-slide"
              >
                <div className="csapat-card12">
                  <img
                    alt={props.member7Alt}
                    src={props.member7Src}
                    className="thq-img-ratio-1-1 csapat-placeholder-image12"
                  />
                  <div className="csapat-content12">
                    <div className="csapat-title11">
                      <span className="csapat-text29 thq-body-small">
                        {props.member7}
                      </span>
                    </div>
                    <span className="csapat-text31 thq-body-small">
                      {props.member7Content}
                    </span>
                  </div>
                </div>
            </div>
            </div>
            <div
              data-thq="slider-pagination"
              className="csapat-slider-pagination1 swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
            >
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet swiper-pagination-bullet-active"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
              <div
                data-thq="slider-pagination-bullet"
                className="swiper-pagination-bullet"
              ></div>
            </div>
            <div
              data-thq="slider-button-prev"
              className="swiper-button-prev"
            ></div>
            <div
              data-thq="slider-button-next"
              className="swiper-button-next"
            ></div>
          </div>
        </div>
      </animate-on-reveal>
    </div>
  )
}

Csapat.defaultProps = {
  member7: 'Kovács Adél',
  member7Content: 'Telefonszám: 06204038020',
  member7Alt: 'Kovács Adél',
  member7Src: './images/Adel.JPG',
  member5Content: 'Telefonszám: 06209643642',
  member4Src:
    './images/Levente.jpg',
  member2Alt: 'Horváth Zsolt',
  member5Alt: 'Tóth László',
  member6: 'Molnár Zsolt',
  member4: 'Helmeci Levente',
  member1Alt: 'Szabó Szabolcs',
  member2: 'Horváth Zsolt',
  member2Src:
    './images/Zsolt2.JPG',
  member6Alt: 'Molnár Zsolt',
  member3: 'Horváth Dániel',
  member6Src:
    './images/Molnar_Zsolt.jpeg',
  heading1: 'Ismerje meg autósiskolánk csapatát!',
  member6Content: 'Telefonszám: 06305310449',
  member5Src:
    './images/Toth_Laszlo.jpg',
  member3Src:
    './images/Daniel2.JPG',
  member1Src:
    './images/Szabi_profil.jpg',
  member1: 'Szabó Szabolcs',
  member2Content: 'Telefonszám: 06706621010',
  member4Alt: 'Helmeci Levente',
  member5: 'Tóth László',
  member3Alt: 'Horváth Dániel',
  member1Content: 'Telefonszám: 06302169252',
  member3Content: 'Telefonszám: 06302172486',
  member4Content: 'Telefonszám: 06309311519',
}

Csapat.propTypes = {
  member5Content: PropTypes.string,
  member4Src: PropTypes.string,
  member2Alt: PropTypes.string,
  member5Alt: PropTypes.string,
  member6: PropTypes.string,
  member4: PropTypes.string,
  member1Alt: PropTypes.string,
  member2: PropTypes.string,
  member2Src: PropTypes.string,
  member6Alt: PropTypes.string,
  member3: PropTypes.string,
  member6Src: PropTypes.string,
  heading1: PropTypes.string,
  member6Content: PropTypes.string,
  member5Src: PropTypes.string,
  member3Src: PropTypes.string,
  member1Src: PropTypes.string,
  member1: PropTypes.string,
  member2Content: PropTypes.string,
  member4Alt: PropTypes.string,
  member5: PropTypes.string,
  member3Alt: PropTypes.string,
  member1Content: PropTypes.string,
  member3Content: PropTypes.string,
  member4Content: PropTypes.string,
}

export default Csapat
