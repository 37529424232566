import React, { Component } from 'react';
import { format, getWeek, startOfWeek, addDays } from 'date-fns';
import './OddEvenCalendar.css';
import { hu } from 'date-fns/locale';

class OddEvenCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      currentWeek: 0,
      oddOrEven: '',
    };
  }

  componentDidMount() {
    this.updateCalendarInfo();
  }

  updateCalendarInfo() {
    const { currentDate } = this.state;
    const week = getWeek(currentDate);
    this.setState({
      currentWeek: week,
      oddOrEven: week % 2 === 0 ? 'Páros' : 'Páratlan',
    });
  }

  renderCalendar() {
    const { currentDate } = this.state;
    const startDate = startOfWeek(currentDate);
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="day" key={i}>
          {format(addDays(startDate, i), 'EEE, LLL do', { locale: hu })}
        </div>
      );
    }

    return <div className="calendar">{days}</div>;
  }

  render() {
    const { currentWeek, oddOrEven } = this.state;
    return (
      <div className="OddEvenCalendar">
        <h2>
          Aktuális hét: {currentWeek}
        </h2>
        {this.renderCalendar()}
      </div>
    );
  }
}

export default OddEvenCalendar;
