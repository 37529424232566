import React from 'react'

import './Footer.css'
import SocialIcons from '../SocialIcons/SocialIcons'

const Footer2 = (props) => {
  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="footer__logo">
          <img
            src="./images/Feher_keret_nelkul_logo.png"
            alt="Balaton Autósiskola logo"
          />
        </div>

        <nav className="footer__nav">
          <a href="/" className="footer__nav-link">
            Főoldal
          </a>
          <a href="/oktatok" className="footer__nav-link">
            Oktatóink
          </a>
          <a href="/arak" className="footer__nav-link">
            Árak
          </a>
          <a href="/kapcsolat" className="footer__nav-link">
            Kapcsolat
          </a>
          <a href="/aszf" className="footer__nav-link">
            Ászf
          </a>
        </nav>
      </div>

      <hr className="footer__separator" />

      <div className="footer__middle">
        <div className="footer__contact">
          <p className="footer__contact-item">
            <b>Elérhetőség:</b> info@autosiskolabalaton.hu
          </p>
          <div className="footer__phone">
            <span className="material-icons">phone</span>
            <span>06 20 403 8020</span>
          </div>
        </div>

        <div className="footer__social">
          <SocialIcons />
        </div>
      </div>

      <hr className="footer__separator" />

      <div className="footer__bottom">
        <span className="footer__copyright">
          © 2023 - {new Date().getFullYear()} autosiskolabalaton.hu
        </span>
      </div>
    </footer>
  );
}

export default Footer2;
