import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


const getWeekInfo = () => {
    const currentDate = new Date();
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
    const pastDaysOfYear = (currentDate - firstDayOfYear) / 86400000;
    const weekNumber = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    const weekType = weekNumber % 2 === 0 ? 'Páros' : 'Páratlan';
  
    return { weekNumber, weekType };
  };

const MyCalendar = () => {
  const [value, setValue] = useState(new Date());

  const onChange = (nextValue) => {
    setValue(nextValue);
  };

  const { weekNumber, weekType } = getWeekInfo();


  return (
    <div className='naptar_main'>
      <div>
        <div>
          <h2>{weekNumber}. hét</h2>
        </div>
        <Calendar
          onChange={onChange}
          value={value}
          locale="hun-HU"
        />
      </div>
    </div>
  );
};

export default MyCalendar;