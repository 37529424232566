import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css'; 
import 'primereact/resources/primereact.min.css';               
import 'primeicons/primeicons.css';                              
import 'primeflex/primeflex.css';

ReactDOM.render(<App />, document.getElementById("root"));