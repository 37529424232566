export const teachersData = [
    {
      name: "Szabó Szabolcs",
      phone: "06302169252",
      instructorId: "15697",
      position: "Balaton autósiskola ügyvezetője, oktatója",
      bio: `Ügyvezetőként szeretném az autósiskola fiatalos, lendületes formáját megőrizni.
            Nyitottan állok az innovációs és technológiai újítások előtt.
            Oktatóként pedig legfőbb célom türelemmel és a legjobb szakmai tudomásomat átadva  végigkísérni a
            tanulókat a kezdeti lépésektől a jogosítvány megszerzéséig. Fontosnak tartom, hogy a levizsgázottak magabiztos
            tudásra tegyenek szert, és biztonságos közlekedői legyenek az utaknak!`,
      car: "VW Polo IV – nagyon komfortos, jól kezelhető városi autó, manuális váltóval.",
      image: "./images/Szabi_profil.jpg",
    },
    {
      name: "Horváth Zsolt",
      phone: "06706621010",
      instructorId: "16087",
      position: "Balaton autósiskola iskolavezetője, oktatója",
      bio: `2017 óta foglalkozom járművezető szakoktatóként.
            Fontosnak tartom, hogy tanulóimat változatos oktatási módszerekkel, gyakorlatiasan, türelemmel irányítsam, vezessem az
            oktatási folyamat során. Célom, hogy a tanulásra fordított idő maximális kihasználtsággal vezessen a kitűzött célhoz,
            a jogosítvány megszerzéséhez.
            Ehhez személy és teherszállítási tapasztalataimat is kamatoztatni tudom. Fiatalos lendülettel várom a tanulni vágyókat.`,
      car: "VW Golf VI dízel, manuális váltóval.",
      image: "./images/Zsolt2.JPG",
    },
    {
      name: "Horváth Dániel",
      phone: "06302172486",
      instructorId: "17262",
      position: "Oktató",
      bio: `Célom, hogy magas színvonalú képzés mellett barátságos környezetben készítsem fel tanulóimat a vezetésre.
            A szakmai tudás átadása jó hangulatú, személyre szabott vezetési órákon történik.
            Fontosnak tartom, hogy a közös munka során kialakuljon a biztonságérzet, amellyel a jogosítvány
            megszerzése után a tanuló nyugodtan vehet részt a közlekedésben.`,
      car: "Suzuki S-cross (2022), számos extrával a sikeres vizsgáért.",
      image: "./images/Daniel2.JPG",
    },
    {
      name: "Helmeci Levente",
      phone: "06309311519",
      instructorId: "15917",
      position: "Oktató",
      bio: `Több évtizedes szakmai tapasztalatommal várom a tanulni vágyókat.`,
      car: "Citroen Xsara Picasso, Dízel.",
      image: "./images/Levente.jpg",
    },
    {
      name: "Tóth László",
      phone: "06209643642",
      instructorId: "14855",
      position: "Motoros oktató",
      bio: `Több évtizedes motoros oktatásban eltöltött tapasztalatommal
             és végtelen türelmemmel várom a motorozni vágyókat!`,
      car: "",
      image: "./images/Toth_Laszlo.jpg",
    },
    {
      name: "Molnár Zsolt",
      phone: "06305310449",
      instructorId: "16095",
      position: "Oktató",
      bio: ``,
      car: "",
      image: "./images/Molnar_Zsolt.jpeg",
    }
  ];
  