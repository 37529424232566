import React from 'react'

import PropTypes from 'prop-types'

import './contact1.css'

const Contact1 = (props) => {
  return (
    <div className="contact1-contact20 thq-section-padding">
      <animate-on-reveal
        animation="fadeIn"
        duration="500ms"
        delay="0s"
        direction="normal"
        easing="ease"
        iteration="1"
      >
        <div
          data-thq-animate-on-reveal="true"
          className="contact1-max-width thq-section-max-width"
        >
          <div className="contact1-content">
            <svg viewBox="0 0 1024 1024" className="thq-icon-medium">
              <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
            </svg>
            <div className="contact1-contact-info">
              <div className="contact1-content1">
                <h3 className="contact1-text thq-heading-3">Email</h3>
              </div>
              <span className="contact1-email thq-body-small">
                {props.email1}
              </span>
            </div>
          </div>
          <div className="contact1-content2">
            <svg viewBox="0 0 1024 1024" className="thq-icon-medium">
              <path d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"></path>
            </svg>
            <div className="contact1-contact-info1">
              <div className="contact1-content3">
                <h3 className="contact1-text1 thq-heading-3">Telefon</h3>
              </div>
              <span className="contact1-phone thq-body-small">
                {props.phone1}
              </span>
            </div>
          </div>
          <div className="contact1-content4">
            <svg viewBox="0 0 1024 1024" className="thq-icon-medium">
              <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
            </svg>
            <div className="contact1-contact-info2">
              <div className="contact1-content5">
                <h3 className="contact1-text2 thq-heading-3">
                  <span>Iroda</span>
                  <br></br>
                </h3>
              </div>
              <span className="contact1-address thq-body-small">
                {props.address1}
              </span>
            </div>
          </div>
        </div>
      </animate-on-reveal>
    </div>
  )
}

Contact1.defaultProps = {
  phone1: '+36 204038020',
  address1: '8600 Siófok, Kálmán Imre sétány 8.',
  email1: 'info@autosiskolabalaton.hu',
}

Contact1.propTypes = {
  phone1: PropTypes.string,
  address1: PropTypes.string,
  email1: PropTypes.string,
}

export default Contact1
