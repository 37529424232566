import { useEffect } from "react";

const DeepLinkRedirect = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const deepLink = urlParams.get("target");

    if (deepLink) {
      window.location.href = deepLink;
    }
  }, []);

  return (
    <div style={styles.container}>
      <h1>Visszairányítunk a Kukucs-ba...</h1>
      <p>Ha automatikusan nem irányít vissza akkor <a href="kukucs://">kattints ide</a>.</p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
  },
};

export default DeepLinkRedirect;
