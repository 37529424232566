import React, { useEffect } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import { Accordion, AccordionTab } from "primereact/accordion";

function BCategory() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - B kategória'
  }, []);

  const fadeVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0 },
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="BCategoryPage"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeVariants}
        transition={{ duration: 0.6 }}
        className="bcategory-page"
      >
        <h1 className="text-center mb-4">Tájékoztatás &quot;B&quot; kategóriás képzéshez</h1>

        <p className="mb-5 bcategory-intro">
          Elméletet (Kreszt) nálunk e-learning rendszeren, otthon saját tempóban,
          számítógépen végezhetik el a tanulók.
        </p>

        <Accordion>
          <AccordionTab header="A tanfolyamra való felvétel">
            <h4 className="mb-3">A képzést előíró jogszabályok</h4>
            <p>
              • A közúti közlekedésről szóló 1988. évi I. törvény<br />
              • A közúti járművezetők és a közúti közlekedési szakemberek
                képzésének és vizsgáztatásának általános szabályairól szóló 179/2011. (IX. 2.) Korm. rendelet<br />
              • A közúti járművezetők és a közúti közlekedési szakemberek
                képzésének és vizsgáztatásának részletes szabályairól szóló 24/2005. (IV. 21.) GKM rendelet alapján<br />
            </p>
          </AccordionTab>

          <AccordionTab header="A jelentkezés feltételei">
            <ul>
              <li>Személyi igazolvány, lakcímkártya (ha van, vezetői engedély)</li>
              <li>Minimum alapfokú iskolai végzettség igazolása</li>
              <li>Orvosi alkalmassági vélemény (háziorvos)</li>
              <li>Kiskorú tanulóknál a Képzési Szerződést a szülőnek, vagy a gondviselőnek is alá kell írnia</li>
              <li>Esetleg egyéb kategóriás vezetői engedélyről fénymásolat szükséges</li>
            </ul>
            <p>
            A tanulónak az első elméleti tanfolyam bejelentkezés dátumától számítva 9
            hónapon belül kell az első elméleti vizsgára jelentkeznie és 12 hónapon belül sikeres vizsgát kell tenni.
            A sikeres elméleti vizsga 2 évig érvényes.
            Ezek feltételek nem teljesülése esetén a tanfolyamot meg kell ismételni.
            Elméleti vizsgára csak az bocsátható, aki legfeljebb az előírt életkornál
            3 hónappal fiatalabb, valamint az e-learning képzésnél a rendszer kiállította
            a „Képzési Igazolást”, és ezután az ügyfélszolgálati irodában a vizsgához szükséges jelentkezési lapot személyesen aláírta.
            </p>
          </AccordionTab>

          <AccordionTab header="Az első elméleti vizsga kiírásának feltételei">
            <p>
              • Az aláírt jelentkezési lap<br />
              • Befizetett vizsgadíj<br />
              • I. fokú orvosi alkalmassági igazolás
            </p>
            <p>
              A pótvizsgát a tanuló személyesen is intézheti a KAV ügyfélszolgálatán.
            </p>
          </AccordionTab>

          <AccordionTab header="A gyakorlati vizsga">
            <p>
            Forgalmi vizsgára csak az bocsátható, aki a kategóriára előírt életkort elérte, az elméleti tárgyból sikeresen vizsgázott, valamint a tanfolyamon kötelezően előírt gyakorlatot elvégezte, s a kötelezően előírt kilométert levezette. Ezen felül az összes levezett óra díját, valamint vizsga díját befizette.
            A tanuló gyakorlati vizsgára bocsátása csak akkor lehetséges, ha minimum 29 órát és 580 kilométert teljesített.
            </p>
          </AccordionTab>

          <AccordionTab header="A pótórák díjai">
            <p>
              A kötelező órák, valamint a megtett távolság teljesítése után a 
              szakoktató javaslatot tehet pótórákra. A pótórák díja azonos az alapórák díjazásával.
            </p>
          </AccordionTab>

          <AccordionTab header="Tan-, valamint vizsgadíj befizetése">
          <h4 className="mb-3">Fizetési módok</h4>
            <p>
              Bankszámlára átutalással (közlemény szükséges), bankkártyás/készpénz az irodában, részletfizetés lehetséges
            </p>
          </AccordionTab>

          <AccordionTab header="Elméleti képzés díja">
          <h4 className="mb-3">Tantermi képzés</h4>
            <p>
              28 óra, napi 4 óra, azaz 7 alkalom (hétköznapokon 17:00 órától).
              19 óra kresz. 6 óra vezetéselmélet és 3 óra szerkezettan bontásban.
              Hiányozni nem lehet, ha mégis akkor pótórán kell részt venni.<br/>
              E-learning KRESZ + elsősegély (hozzáférési időintervallum)<br />
              180 nap – 75 óra &emsp; &emsp; 49.000 Ft - B<br />
              A tanuló a rendszerbe való belépéstől számítva 180 napon belül 75 órát használhat fel. Amennyiben nem sikerült befejeznie a tanfolyamot, vásárolhat még plusz 10 órát, amelyet 30 napon belül kell teljesítenie (ennek ára: 8 000 Ft).
              A tanfolyamot a regisztrációtól számítva 90 napon belül el kell kezdeni. Amennyiben nem kezdi el, a tandíjat nem áll módunkban visszafizetni.
              Az elméleti tanfolyam befejezése után a tanuló tanúsítványt kap.
              Az első elméleti vizsgára való jelentkezést az autósiskola intézi. Sikertelen vizsga esetén a tanuló a KAV kijelölt irodájában a vizsgadíj befizetése után kérheti a következő vizsga időpontját.
              Az elméleti képzést, valamint az első vizsgadíjat egy összegben kell befizetni a beiratkozáskor.
            </p>
          </AccordionTab>

          <AccordionTab header="Gyakorlati képzés">
            <p>
            1-30 óráig:<br />
            &emsp;- 9500 Ft/óra<br />
            31 órától: <br />
            &emsp;- 9500 Ft/óra<br />
            A gyakorlati képzés megkezdésének feltétele a sikeres elméleti vizsga.
            Az előirt minimum óraszámok 29+1 óra, amelyek az alábbiakból állnak:<br />
            a.) 9 óra alapoktatás<br />
            b.) 20 óra főoktatás:<br />
            &emsp;•	14 óra városi<br />
            &emsp;•	4 óra országúti és<br />
            &emsp;•	2 óra éjszakai vezetés<br />
            &emsp;•	+ 1 óra vizsga<br />
            A gyakorlati tanórák 50 percesek. A tanóra díjját mindig előre kell rendezni. Az autósiskola ügyfélfogadó irodájában
            vagy átutalással! Tandíj átvételére az oktató nem jogosult!
            </p>
          </AccordionTab>

          <AccordionTab header="Vizsgadíjak">
            <p>
            A közúti járművezetők vizsgáztatásával összefüggő díjakat a 84/2009. (XII. 30.)
            KHEM rendelet mellékletében található, amelyek az alábbi árakat tartalmazza:<br />
            Elméleti vizsga: 4.600 Ft<br />
            Helyszíne KAV vizsgaközpont: 8600 Siófok, Bajcsy-Zsilinszky u. 212.<br />
            Forgalmi vizsga: 11.000 Ft<br />
            Az elméleti és forgalmi vizsga díját a tanuló személyesen befizeti az iskola pénztárába,
            amelyről igazolást kap. Megbízza ügyintézőnket a vizsgára való jelentkezés lebonyolításával.
            </p>
          </AccordionTab>

          <AccordionTab header="A tanuló jogai és kötelezettségei">
            <p>
            a.) A tanulónak joga van a szerződésben meghatározott tanfolyamon részt vennie, valamint elméleti és gyakorlati vizsgát tennie.<br />
            b.) A tanulónak a személyazonosságát érvényes személyi igazolvánnyal kell igazolnia.<br />
            Legkésőbb a vizsga napján igazolnia kell a vizsgaközpont részére az alapfokú
            iskolai végzettségét. Amennyiben nem igazolta annak teljesítéséig a
            következő vizsgára nem bocsátható, valamint részére vizsgaigazolás nem állítható ki.
            Az iskolai végzettség megléte igazolható:<br />
            &emsp;•	személyazonosító okmányokba Magyarországon bejegyzett saját jogon szerzett doktori címmel<br />
            &emsp;•	iskolai végzettség igazolására szóló okmány eredeti, vagy a
            közjegyző, vagy a kiállító által hitelesített példányával. Külföldi
            bizonyítványok és oklevelek esetén az eredeti okmányokkal és annak
            hiteles fordításával (a hiteles fordítás nem kötelező, ha az oklevél, vagy
            bizonyítvány adott tartalmában és formájában szerepel a közlekedési hatóság
            által létrehozott Bizonyítvány és Oklevél nyilvántartásában)<br />
            &emsp;•	külföldi bizonyítványok és oklevelek esetén olyan – hatáskörrel
            rendelkező – magyar hatóság által hozott elismerő határozattal, honosítási
            záradékkal, hatósági bizonyítvánnyal, hatósági igazolással, tájékoztatással
            vagy három hónapnál nem régebbi Magyarországon felsőoktatási intézmény által
            kiállított hallgatói jogviszony igazolással, amely kimondja, hogy az adott
            bizonyítvány vagy oklevél legalább alapfokú végzettséget igazol, vagy annak meglétét előfeltételezi.<br />
            c.) Amennyiben a tanuló a vizsgán valamelyik vizsgatárgyból nem felelt meg,
            akkor a vizsgadíj újbóli befizetése után tehet újabb vizsgát.<br />
            d.) A vizsgán meg nem jelenés, késés, vagy sikertelen vizsga esetén újabb vizsga csak a vizsgadíj befizetése után lehetséges.<br />
            e.) A tanuló személyi adataiban történő változást 8 napon belül köteles bejelenteni a képzőszerv, illetve a KAV felé.<br />
            </p>
          </AccordionTab>

          <AccordionTab header="A képzőszerv jogai és kötelezettségei">
            <p>
            a.) A képzőszerv jogosult megtagadni az oktatást, ha a tanuló
            ittasan, drogosan, fertőző betegen, vagy szennyezett ruhában jelenik meg.<br />
            b.) Jogosult a tanuló oktatását felfüggeszteni, ha a tandíjfizetés elmarad.<br />
            c.) A Balaton Autósiskola jogosult az ár változtatására.<br />
            d.) Köteles a tanulót a vizsgára felkészíteni.<br />
            e.) Köteles a tanuló kérésére pontos elszámolást adni a befizetett tandíj mértékéről és a leoktatott órákról.<br />
            f.) A gyakorlati oktatás során az oktatóval előre leegyeztetett
            időpontot 2 munkanappal előtte van lehetősége lemondani. Ellenkező
            esetben a tanóráról való hiányzást az oktató a tanulót kettő tanórával terheli meg.<br />
            g.) Köteles a tanulónak az áthelyezéshez szükséges nyomtatványokat a kéréstől számítva három munkanapon belül kiadni.<br />
            </p>
          </AccordionTab>

          <AccordionTab header="A tanuló áthelyezése">
            <p>
            Amennyiben a tanuló más képzőszervnél kívánja folytatni a tanulmányait, úgy
            a képzőszerv képzési igazolást állít ki a kéréstől számítva három munkanapon
            belül. A fel nem használt tandíj visszafizetésre kerül.<br />
            Más képzőszervtől átvett tanuló esetén a gyakorlati oktatás díja: 11.000 Ft/óra<br />
            </p>
          </AccordionTab>
        </Accordion>
      </motion.div>
    </AnimatePresence>
  );

  // return (
  //   <AnimatePresence mode="wait">
  //     <motion.div
  //       key="homePage"
  //       initial={{ opacity: 0 }}
  //       animate={{ opacity: 1 }}
  //       exit={{ opacity: 0 }}
  //     >
  //       <div className='title'>
  //         Tájékoztatás "B" kategóriás képzéshez
  //       </div>
  //       <div className='bcategory'>
  //         Elméletet (Kreszt) nálunk e-learning rendszeren, otthon saját tempóban számítógépen végezhetik el a tanulók.
  //       </div>
  //       <div className='low-title'>
  //         <Fade bottom>
  //         <p className='bcat'>
  //           A tanfolyamra való felvétel
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat2'>
  //           A képzést előíró jogszabályok
  //         </p>
  //         <p className='low-title-content2'>
  //           &emsp;•	A közúti közlekedésről szóló 1988. évi I. törvény<br />
  //           &emsp;•	A közúti járművezetők és a közúti közlekedési szakemberek
  //           képzésének és vizsgáztatásának általános szabályairól szóló 179/2011. (IX. 2.) Korm. rendelet<br />
  //           &emsp;•	A közúti járművezetők és a közúti közlekedési szakemberek
  //           képzésének és vizsgáztatásának részletes szabályairól szóló 24/2005. (IV. 21.) GKM rendelet alapján<br />
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat2'>
  //           A jelentkezés feltételei
  //         </p>
  //         <p className='low-title-content2'>
  //           &emsp;•	személyi igazolvány, lakcímkártya, (ha van, vezetői engedély)<br />
  //           &emsp;•	minimum alapfokú iskolai végzettséget igazoló okmány bemutatása<br />
  //           &emsp;•	az előírt egészségi alkalmassági orvosi vélemény (háziorvos)<br />
  //           &emsp;•	kiskorú tanulóknál a Képzési Szerződést a szülőnek, vagy a gondviselőnek is alá kell írnia<br />
  //           &emsp;•	esetleg egyéb kategóriás vezetői engedélyről fénymásolat szükséges<br />
  //           A tanulónak az első elméleti tanfolyam bejelentkezés dátumától számítva 9
  //           hónapon belül kell az első elméleti vizsgára jelentkeznie és 12 hónapon belül sikeres vizsgát kell tenni.
  //           A sikeres elméleti vizsga 2 évig érvényes.
  //           Ezek feltételek nem teljesülése esetén a tanfolyamot meg kell ismételni.
  //           Elméleti vizsgára csak az bocsátható, aki legfeljebb az előírt életkornál
  //           3 hónappal fiatalabb, valamint az e-learning képzésnél a rendszer kiállította
  //           a „Képzési Igazolást”, és ezután az ügyfélszolgálati irodában a vizsgához szükséges jelentkezési lapot személyesen aláírta.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat2'>
  //           Az első elméleti vizsga kiírásának feltételei
  //         </p>
  //         <p className='low-title-content2'>
  //           &emsp;•	az aláírt jelentkezési lap<br />
  //           &emsp;•	befizetett vizsgadíj<br />
  //           &emsp;•	I. fokú orvosi alkalmassági igazolás<br />
  //           A pótvizsgát a tanuló jogosult személyesen intézni a KAV ügyfélszolgálatán.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat2'>
  //           A gyakorlati vizsga
  //         </p>
  //         <p className='low-title-content2'>
  //           Forgalmi vizsgára csak az bocsátható, aki a kategóriára előírt életkort elérte, az elméleti tárgyból sikeresen vizsgázott, valamint a tanfolyamon kötelezően előírt gyakorlatot elvégezte, s a kötelezően előírt kilométert levezette. Ezen felül az összes levezett óra díját, valamint vizsga díját befizette.
  //           A tanuló gyakorlati vizsgára bocsátása csak akkor lehetséges, ha minimum 29 órát és 580 kilométert teljesített.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat2'>
  //           A pótórák díjai:
  //         </p>
  //         <p className='low-title-content2'>
  //           a kötelező órák, valamint a megtett távolság teljesítése
  //           után a szakoktató javaslatot tehet a további gyakorlati órák felvételére. A pótórák díja azonos az alapórák díjazásával.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat'>
  //           Tan-, valamint vizsgadíj befizetése
  //         </p>
  //         <p className='bcat2'>
  //           Fizetési módok
  //         </p>
  //         <p className='low-title-content2'>
  //           &emsp;•	bankszámlára átutalással (közlemény szükséges)<br />
  //           &emsp;•	bankkártyás, vagy készpénz fizetési lehetőség az irodában<br />
  //           &emsp;•	részletfizetési lehetőség<br />
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat2'>
  //           Elméleti képzés díja
  //         </p>
  //         <p className='low-title-content2'>
  //           Tantermi képzés:<br/>
  //           28 óra, napi 4 óra, azaz 7 alkalom (hétköznapokon 17:00 órától).
  //           19 óra kresz. 6 óra vezetéselmélet és 3 óra szerkezettan bontásban.
  //           Hiányozni nem lehet, ha mégis akkor pótórán kell részt venni.<br/>
  //           E-learning KRESZ + elsősegély (hozzáférési időintervallum)<br />
  //           180 nap – 75 óra &emsp; &emsp; 49.000 Ft - B<br />
  //           A tanuló a rendszerbe való belépéstől számítva 180 napon belül 75 órát használhat fel. Amennyiben nem sikerült befejeznie a tanfolyamot, vásárolhat még plusz 10 órát, amelyet 30 napon belül kell teljesítenie (ennek ára: 8 000 Ft).
  //           A tanfolyamot a regisztrációtól számítva 90 napon belül el kell kezdeni. Amennyiben nem kezdi el, a tandíjat nem áll módunkban visszafizetni.
  //           Az elméleti tanfolyam befejezése után a tanuló tanúsítványt kap.
  //           Az első elméleti vizsgára való jelentkezést az autósiskola intézi. Sikertelen vizsga esetén a tanuló a KAV kijelölt irodájában a vizsgadíj befizetése után kérheti a következő vizsga időpontját.
  //           Az elméleti képzést, valamint az első vizsgadíjat egy összegben kell befizetni a beiratkozáskor.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat2'>
  //           Gyakorlati képzés
  //         </p>
  //         <p className='low-title-content2'>
  //           1-30 óráig:<br />
  //           &emsp;- 9500 Ft/óra<br />
  //           31 órától: <br />
  //           &emsp;- 9500 Ft/óra<br />
  //           A gyakorlati képzés megkezdésének feltétele a sikeres elméleti vizsga.
  //           Az előirt minimum óraszámok 29+1 óra, amelyek az alábbiakból állnak:<br />
  //           a.) 9 óra alapoktatás<br />
  //           b.) 20 óra főoktatás:<br />
  //           &emsp;•	14 óra városi<br />
  //           &emsp;•	4 óra országúti és<br />
  //           &emsp;•	2 óra éjszakai vezetés<br />
  //           &emsp;•	+ 1 óra vizsga<br />
  //           A gyakorlati tanórák 50 percesek. A tanóra díjját mindig előre kell rendezni. Az autósiskola ügyfélfogadó irodájában
  //           vagy átutalással! Tandíj átvételére az oktató nem jogosult!
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat'>
  //           Vizsgadíjak
  //         </p>
  //         <p className='low-title-content2'>
  //           A közúti járművezetők vizsgáztatásával összefüggő díjakat a 84/2009. (XII. 30.)
  //           KHEM rendelet mellékletében található, amelyek az alábbi árakat tartalmazza:<br />
  //           Elméleti vizsga: 4.600 Ft<br />
  //           Helyszíne KAV vizsgaközpont: 8600 Siófok, Bajcsy-Zsilinszky u. 212.<br />
  //           Forgalmi vizsga: 11.000 Ft<br />
  //           Az elméleti és forgalmi vizsga díját a tanuló személyesen befizeti az iskola pénztárába,
  //           amelyről igazolást kap. Megbízza ügyintézőnket a vizsgára való jelentkezés lebonyolításával.
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat'>
  //           A tanuló jogai és kötelezettségei
  //         </p>
  //         <p className='low-title-content2'>
  //           a.) A tanulónak joga van a szerződésben meghatározott tanfolyamon részt vennie, valamint elméleti és gyakorlati vizsgát tennie.<br />
  //           b.) A tanulónak a személyazonosságát érvényes személyi igazolvánnyal kell igazolnia.<br />
  //           Legkésőbb a vizsga napján igazolnia kell a vizsgaközpont részére az alapfokú
  //           iskolai végzettségét. Amennyiben nem igazolta annak teljesítéséig a
  //           következő vizsgára nem bocsátható, valamint részére vizsgaigazolás nem állítható ki.
  //           Az iskolai végzettség megléte igazolható:<br />
  //           &emsp;•	személyazonosító okmányokba Magyarországon bejegyzett saját jogon szerzett doktori címmel<br />
  //           &emsp;•	iskolai végzettség igazolására szóló okmány eredeti, vagy a
  //           közjegyző, vagy a kiállító által hitelesített példányával. Külföldi
  //           bizonyítványok és oklevelek esetén az eredeti okmányokkal és annak
  //           hiteles fordításával (a hiteles fordítás nem kötelező, ha az oklevél, vagy
  //           bizonyítvány adott tartalmában és formájában szerepel a közlekedési hatóság
  //           által létrehozott Bizonyítvány és Oklevél nyilvántartásában)<br />
  //           &emsp;•	külföldi bizonyítványok és oklevelek esetén olyan – hatáskörrel
  //           rendelkező – magyar hatóság által hozott elismerő határozattal, honosítási
  //           záradékkal, hatósági bizonyítvánnyal, hatósági igazolással, tájékoztatással
  //           vagy három hónapnál nem régebbi Magyarországon felsőoktatási intézmény által
  //           kiállított hallgatói jogviszony igazolással, amely kimondja, hogy az adott
  //           bizonyítvány vagy oklevél legalább alapfokú végzettséget igazol, vagy annak meglétét előfeltételezi.<br />
  //           c.) Amennyiben a tanuló a vizsgán valamelyik vizsgatárgyból nem felelt meg,
  //           akkor a vizsgadíj újbóli befizetése után tehet újabb vizsgát.<br />
  //           d.) A vizsgán meg nem jelenés, késés, vagy sikertelen vizsga esetén újabb vizsga csak a vizsgadíj befizetése után lehetséges.<br />
  //           e.) A tanuló személyi adataiban történő változást 8 napon belül köteles bejelenteni a képzőszerv, illetve a KAV felé.<br />
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat'>
  //           A képzőszerv jogai és kötelezettségei
  //         </p>
  //         <p className='low-title-content2'>
  //           a.) A képzőszerv jogosult megtagadni az oktatást, ha a tanuló
  //           ittasan, drogosan, fertőző betegen, vagy szennyezett ruhában jelenik meg.<br />
  //           b.) Jogosult a tanuló oktatását felfüggeszteni, ha a tandíjfizetés elmarad.<br />
  //           c.) A Balaton Autósiskola jogosult az ár változtatására.<br />
  //           d.) Köteles a tanulót a vizsgára felkészíteni.<br />
  //           e.) Köteles a tanuló kérésére pontos elszámolást adni a befizetett tandíj mértékéről és a leoktatott órákról.<br />
  //           f.) A gyakorlati oktatás során az oktatóval előre leegyeztetett
  //           időpontot 2 munkanappal előtte van lehetősége lemondani. Ellenkező
  //           esetben a tanóráról való hiányzást az oktató a tanulót kettő tanórával terheli meg.<br />
  //           g.) Köteles a tanulónak az áthelyezéshez szükséges nyomtatványokat a kéréstől számítva három munkanapon belül kiadni.<br />
  //         </p>
  //         </Fade>
  //         <Fade bottom>
  //         <p className='bcat'>
  //           A tanuló áthelyezése
  //         </p>
  //         <p className='low-title-content2'>
  //           Amennyiben a tanuló más képzőszervnél kívánja folytatni a tanulmányait, úgy
  //           a képzőszerv képzési igazolást állít ki a kéréstől számítva három munkanapon
  //           belül. A fel nem használt tandíj visszafizetésre kerül.<br />
  //           Más képzőszervtől átvett tanuló esetén a gyakorlati oktatás díja: 11.000 Ft/óra<br />
  //         </p>
  //         </Fade>
  //       </div>
  //     </motion.div>
  //   </AnimatePresence>
  // )
}
export default BCategory;