import React from 'react'
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Panel } from "primereact/panel";

function AllamiTamogatas() {
  useEffect(() => {
    document.title = 'Balaton Autósiskola - Állami támogatás'
  }, []);

  const fadeVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0 }
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key="allamiTamogatasPage"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeVariants}
        transition={{ duration: 0.6 }}
        className="allami-page"
      >
        <Panel header="Ki jogosult az állami támogatásra?" className="custom-panel mb-4">
          <p>
            <strong>1.</strong> Magyar állampolgár, (vagy a nemzeti felsőoktatásról szóló 2011. évi CCIV. törvény 39. § (1) bekezdés a) és c)–h) pontjában meghatározott személy) vagy <br />
            <strong>2.</strong> A vizsga napján a 20. életévét nem töltötte még be. <br />
            <strong>3.</strong> A 20. életévét már betöltötte, és a vizsga napján:
          </p>
          <ul>
            <li>csecsemőgondozási díjban</li>
            <li>gyermekgondozási díjban</li>
            <li>gyermekgondozást segítő ellátásban</li>
          </ul>
          <p>
            <strong>4.</strong> Sikeres KRESZ-vizsga.
          </p>
        </Panel>

        <Panel header="Támogatás mértéke" className="custom-panel mb-4">
          <p>
            Az 55//2018 (III.23.) Korm. rendelet alapján 
            <strong> 25.000 Ft</strong> állami támogatást kaphat vissza.
          </p>
        </Panel>

        <Panel header="Mit kell tenni ahhoz, hogy megkapja az állami támogatást?" className="custom-panel">
          <ul>
            <li>
              Az igazoltan elvégzett elméleti tanfolyam, illetve a sikeres KRESZ-vizsga után
              bejön az irodánkba, ahol kiállítunk egy igazolást a befizetett összegről.
            </li>
            <li>
              Adja be az iskolától kapott igazolást a Magyar Államkincstár
              Nyugdíjfolyósító Igazgatósághoz (személyesen / elektronikusan), ez a sikeres KRESZ-vizsgától számított 1 éven belül lehetséges.
            </li>
            <li>30 napon belül a megadott számlára kerül az összeg.</li>
          </ul>
        </Panel>
      </motion.div>
    </AnimatePresence>
  );

  // return (
  //   <AnimatePresence mode="wait">
  //     <motion.div
  //       key="homePage"
  //       initial={{ opacity: 0 }}
  //       animate={{ opacity: 1 }}
  //       exit={{ opacity: 0 }}
  //     >
  //       <div className='title'>
  //         Ki jogosult az állami támogatásra?
  //       </div>
  //       <div className='low-title'>
  //         <p className='low-title-content2'>
  //           1. Magyar állampolgár, (vagy a nemzeti felsőoktatásról szóló 2011. évi CCIV. törvény 39. § (1) bekezdés a) és c)–h) pontjában meghatározott személy) vagy<br />
  //           2. A vizsga napján a 20. életévét nem töltötte még be.<br />
  //           3. A 20. életévét már betöltötte és a vizsga napján:<br />
  //           &emsp;•	csecsemőgondozási díjban,<br />
  //           &emsp;•	gyermekgondozási díjban vagy<br />
  //           &emsp;•	gyermekgondozást segítő ellátásban
  //           részesült, és nem vette korábban igénybe a támogatást.<br />
  //           4. Sikeres KRESZ-vizsga.
  //         </p>
  //         <p className='low-titles'>
  //           Támogatás mértéke
  //         </p>
  //         <p className='low-title-content2'>
  //           Az 55//2018 (III.23.) Korm. rendelet alapján 25.000 Ft állami támogatást kap vissza.
  //         </p>
  //         <p className='low-titles'>
  //           Mit kell tenni ahhoz, hogy megkapja az állami támogatást?
  //         </p>
  //         <p className='low-title-content3'>
  //           •	Az igazoltan elvégzett elméleti tanfolyam, valamint
  //           a sikeres KRESZ-vizsga után bejön az irodánkba, ahol kiállítunk egy igazolást a befizetett összegről.<br />
  //           •	Beadja az iskolától kapott igazolást a Magyar
  //           Államkincstár Nyugdíjfolyósító Igazgatósághoz
  //           (személyesen vagy elektronikusan), ez a sikeres KRESZ-vizsgától számított 1 éven belül lehetséges.<br />
  //           •	30 napon belül a megadott számlára kerül az összeg.
  //         </p>
  //       </div>
  //     </motion.div>
  //   </AnimatePresence>
  // )
}
export default AllamiTamogatas;