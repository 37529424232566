import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from './Firebase';
import { 
    onAuthStateChanged, 
    signInWithPopup, 
    GoogleAuthProvider, 
    signInWithEmailAndPassword, 
    createUserWithEmailAndPassword,
    deleteUser,
    signOut
  } from 'firebase/auth';
import { doc, getDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useSuccessMessage } from './SuccessMessageContext';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const { setSuccessMessage } = useSuccessMessage();

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      localStorage.removeItem('authTime');
      // console.log("Logged out successfully!");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    const checkSessionTimeout = () => {
      const authTime = localStorage.getItem('authTime');
      if (authTime) {
        const elapsedTime = Date.now() - parseInt(authTime, 10);

        const currentPath = window.location.pathname;
        if (currentPath === '/live' || currentPath.includes('simplepay')) {
          // console.log("User is on a safe page, skipping logout");
          return;
        }

        if (elapsedTime > 10 * 60 * 1000) {
          logout();
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      setLoading(false);
      if (user) {
        const userDoc = await getDoc(doc(db, 'subscriptions', user.uid));
        setHasAccess(userDoc.exists() && userDoc.data().isSubscribed);

        if (!localStorage.getItem('authTime')) {
          localStorage.setItem('authTime', Date.now().toString());
        }

        checkSessionTimeout();
      }
    });

    const intervalId = setInterval(checkSessionTimeout, 60 * 1000);

    return () => {
      clearInterval(intervalId);
      unsubscribe();
    };
  }, []);

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      setSuccessMessage('Sikeres bejelentkezés!');
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user') {
        setErrorMessage('A Google bejelentkezési ablakot bezárták. Próbálja újra.');
      } else {
        setErrorMessage('Hiba történt a Google fiókkal való bejelentkezéskor.');
        console.error("Login failed: ", error);
      }
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 5000);
    }
  };

  const loginWithEmail = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const registerWithEmail = async (email, password) => {
    await createUserWithEmailAndPassword(auth, email, password);
  };

  const deleteUserAccount = async () => {
    if (user) {
      const userDocRef = doc(db, 'subscriptions', user.uid);
      await deleteDoc(userDocRef);

      const otherCollectionRefs = query(collection(db, 'otherCollection'), where('uid', '==', user.uid));
      const querySnapshot = await getDocs(otherCollectionRefs);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      await deleteUser(user);
      setUser(null);
    }
  };

  const value = {
    user,
    loading,
    hasAccess,
    loginWithGoogle,
    loginWithEmail,
    registerWithEmail,
    deleteUserAccount,
    logout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
